<template>
  <div id="myTeam" class="myTeam">
    <v-alert dense class="alert" :value="tipsInfo" :type="tipsInfoType">{{ tipsInfoName }}</v-alert>
    <v-data-table :headers="headers" :items="desserts" class="table" hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat>
          <v-icon color="#666" class="goback" @click="goBackClick">mdi-arrow-left-circle</v-icon>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title> 团队管理 </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="420px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="addClick" color="primary" style="margin-top: 10px" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon color="#fff">mdi-plus</v-icon>
                邀请
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">邀请成员</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="formUser" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        label="成员 *"
                        outlined
                        :items="StaffList"
                        :item-text="(item) => `${item.userName}`"
                        :item-value="(item) => `${item.userId}`"
                        :rules="rules"
                        v-model="addUser.userId"
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#ccc darken-4" @click="close"> 取消 </v-btn>
                <v-btn color="blue darken-2" style="color: #fff" @click="addItemConfirm"> 确认 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="340px">
            <v-card>
              <v-card-title class="text-h5">是否要删除当前成员？</v-card-title>
              <v-card-text>*注意：删除后此成员将不能在操作此项目！</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-2" text @click="closeDelete"> 取消 </v-btn>
                <v-btn color="light-blue accent-3" text @click="deleteItemConfirm"> 确认 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.userName`]="{ item }">
        <div>{{ item.user.userName }}</div>
      </template>
      <template v-slot:[`item.nickName`]="{ item }">
        <div>{{ item.user.nickName }}</div>
      </template>
      <template v-slot:[`item.projectRoleId`]="{ item }">
        <v-select
          v-if="item.projectRole.priority > loginRole && showRole"
          class="permission"
          style="font-size:14px"
          :items="permissionList"
          :item-text="(item) => `${item.projectRoleNickName}`"
          :item-value="(item) => `${item.projectRoleId}`"
          :value="item.projectRole.projectRoleId"
          @change="
            (val) => {
              updateRole(val, item)
            }
          "
        ></v-select>
        <div style="height:70px; line-height: 70px;" v-else>{{item.projectRole.projectRoleNickName}}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small color="red darken-1" v-if="item.projectRole.projectRoleName != 'Creater'" @click="deleteItem(item)"> mdi-trash-can-outline </v-icon>
      </template>
      <template v-slot:no-data>
        <div color="primary" @click="initialize">暂无数据</div>
      </template>
    </v-data-table>
    <div class="pagination">
        <v-pagination class="pagination_con" v-model="pageNumber" :length="totalPages" :total-visible="7" @input="pageChange"></v-pagination>
      </div>
  </div>
</template>

<script>
import {
  getProjectUser,
  getProjectRole,
  updateProjectUser,
  deleteProjectTeam,
  getProjectStaff,
  addProjectUser,
} from '@/api/project'
import { getOid,getOidType } from '@/utils/auth'
export default {
  data: () => ({
    valid:true,
    dialog: false,
    dialogDelete: false,
    tipsInfo: false,
    tipsInfoName: '',
    tipsInfoType: 'success',
    pageNumber: 1,
    showRole:true,
    pageSize: 10,
    totalPages: 1,
    addUser:{
      userId:''
    },
    headers: [
      {
        text: '成员账号',
        align: 'start',
        sortable: false,
        value: 'userName',
        divider: false,
      },
      { text: '成员姓名', value: 'nickName' },
      { text: '成员权限', value: 'projectRoleId' },
      { text: '操作', value: 'actions', sortable: false },
    ],
    permissionList: [],
    StaffList: [],
    desserts: [],
    userProjectRelId: '',
    rules: [(value) => !!value || '请输入'], // 验证
    loginRole:'',
  }),
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.loginRole = this.$route.query.priority
    this.initialize()
    this.roleList()
    this.getProjectStafflist()
  },

  methods: {
    //分页
    pageChange(e) {
      this.initialize()
    },
    //获取当前员工列表
    initialize() {
      getProjectUser({
        projectId: this.$route.query.projectId,
        pageNumber: this.pageNumber - 1,
        pageSize: this.pageSize,
      })
        .then((res) => {
          this.desserts = res.data.content
          this.totalPages = res.data.totalPages
        })
        .catch(() => {})
    },
    //获取权限列表
    roleList() {
      getProjectRole()
        .then((res) => {
          this.permissionList = res.data
        })
        .catch(() => {})
    },
    //修改权限
    updateRole(val, item) {
      let data = {
        projectRoleId: val,
        userId: item.user.userId,
        userProjectRelId: item.userProjectRelId,
        projectId: item.project.projectId,
      }
      updateProjectUser(data)
        .then((res) => {
          this.tipsInfo = true
          this.tipsInfoType = 'success'
          this.tipsInfoName = '修改成功'
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
        .catch((error) => {
          let err = error.response.data
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoName = err.message
          this.showRole =false
          setTimeout(() => {
            this.showRole =true
          }, 1)
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
    },
    //获取项目员工列表
    getProjectStafflist() {
      getProjectStaff(this.$route.query.projectId)
        .then((res) => {
          this.StaffList = res.data
        })
        .catch(() => {})
    },
    deleteItem(item) {
      this.dialogDelete = true
      this.userProjectRelId = item.userProjectRelId
    },
    //新增
    addClick(){
      this.dialog = true
      this.$nextTick(function () {
        this.$refs.formUser.resetValidation()
      })
    },
    //新增成员
    addItemConfirm(){
      this.$refs.formUser.validate()
      if (!this.addUser.userId) {
        return false
      }
      let data = {
        userId:this.addUser.userId,
        projectId:this.$route.query.projectId
      }
      addProjectUser(data)
        .then((res) => {
          this.tipsInfo = true
          this.tipsInfoType = 'success'
          this.tipsInfoName = '邀请成功'
          this.initialize()
          this.getProjectStafflist()
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
        .catch((error) => {
          let err = error.response.data
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoName = err.message
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
      this.close()
    },
    //删除成员
    deleteItemConfirm() {
      deleteProjectTeam({id:this.userProjectRelId})
        .then((res) => {
          this.tipsInfo = true
          this.tipsInfoType = 'success'
          this.tipsInfoName = '删除成功'
          this.initialize()
          this.getProjectStafflist()
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
        .catch((error) => {
          let err = error.response.data
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoName = err.message
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
      this.closeDelete()
    },
    close() {
      this.dialog = false
    },
    closeDelete() {
      this.dialogDelete = false
    },
    goBackClick() {
      this.$router.go(-1)
    },
    onCopy(e) {
      this.tipsInfo = true
      this.tipsInfoType = 'success'
      this.tipsInfoName = '复制成功'
      setTimeout(() => {
        this.tipsInfo = false
      }, 2000)
    },
  },
}
</script>
<style lang="scss">
.myTeam {
  .v-toolbar__content {
    padding: 4px 0;
  }
  .permission {
    width: 100px;
  }
  .table {
    width: calc(100% - 32px);
    margin: 0 auto;
    border-bottom: 1px solid #ddd;
    table {
      border: none;
      th {
        border: none;
        font-size: 14px !important;
      }
      td {
        border: none;
      }
    }
  }
  .pagination {
    width: 100%;
    height: 80px;
    position: relative;
    .pagination_con {
      position: absolute;
      right: 0;
      top: 20px;
    }
  }
  .goback {
    cursor: pointer;
  }
  .goback:hover {
    color: #536dfe !important;
  }
}
</style>